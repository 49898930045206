import request from '@/axios/index';

export function getBanner1() {
    return request({
        url: '/common/city',
        method: 'get'
    })
}
export function getBanner(query) {
    return request({
        url: '/api/app/v1/index/banner/list',
        method: 'post',
        data: query
    })
}
export function theme1(query) {
    console.log(query)
    return request({
        url: '/api/app/v1/index/course/recommend/list',
        method: 'post',
        data: query
    })
}
export function theme2(query) {
    return request({
        url: '/api/app/v1/index/course/recommend/list',
        method: 'post',
        data: query
    })
}

export function theme3(query) {
    return request({
        url: '/api/app/v1/index/course/recommend/type/list',
        method: 'post',
        data: query
    })
}

export function theme4(query) {
    return request({
        url: '/api/app/v1/index/course/search/list',
        method: 'post',
        data: query
    })
}

export function theme5(query) {
    return request({
        url: '/api/app/v1/index/course/type/list',
        method: 'post',
        data: query
    })
}

export function theme6(query) {
    return request({
        url: '/api/app/v1/index/get/application/guide/info',
        method: 'post',
        data: query
    })
}
export function theme7(query) {
    return request({
        url: '/api/app/v1/index/hot/search/list',
        method: 'post',
        data: query
    })
}
export function theme8(query) {
    return request({
        url: '/api/app/v1/index/live/list',
        method: 'post',
        data: query
    })
}
// 名师答疑
export function theme9(query) {
    return request({
        url: '/api/app/v1/index/wd/recommend/list',
        method: 'post',
        data: query
    })
}
export function theme10(query) {
    return request({
        url: '/api/app/v1/index/wd/search/list',
        method: 'post',
        data: query
    })
}
// 问答
export function theme11(query) {
    return request({
        url: '/api/app/v1/index/wd/search/list',
        method: 'post',
        data: query
    })
}
// 预约直播
export function reserve(query) {
    return request({
        url: '/api/app/v1/live/reserve',
        method: 'post',
        data: query
    })
}

// homeLiveList 获取直播列表
export function liveList(data) {
    return request({
        url: '/api/app/v1/live/list',
        method: 'post',
        data: data
    })
}

// homeLiveList 预约直播
export function liveYuYue(data) {
    return request({
        url: '/api/app/v1/live/reserve',
        method: 'post',
        data: data
    })
}


// homeGrid 获取课程
export function getPackageList(data) {
    return request({
        url: '/api/app/v1/course/package/list',
        method: 'post',
        data: data
    })
}

// 广告弹窗
export function popupWindowList(data) {
    return request({
        url: '/api/h5/v1/index/advertise/popupWindowList',
        method: 'get',
        params: data
    })
}
