<template>
    <div class="homeLiveList">
        <!-- 头部 -->
        <van-nav-bar class="navBar" z-index="9999" fixed :placeholder="true" @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="22" color="#141414" />
            </template>
            <template #title>
                <span style="font-size:17px;font-weight:500;">直播列表</span>
            </template>
        </van-nav-bar>

        <!-- tab列表 -->
        <van-tabs v-model="activeName" sticky offset-top="46px" swipeable animated color="#2470F4">

            <van-tab title="今日直播" name="a">
                <van-pull-refresh v-model="todayLoading" @refresh="TodayRefresh">
                    <div class="todayBox" v-if="todayList.length > 0">
                        
                        <!-- 今日直播 -->
                        <div class="item" v-for="(item, index) in todayList" :key="index" @click="onYuYue(item)">
                            <div class="left">
                                <div class="avatar">
                                    <img :src="item.teacherHeaderPath" class="avatar_img" />
                                    <div class="avatar_icon"></div>
                                </div>
                                <div class="avatar_text">{{ item.typeName }}</div>
                            </div>
                            <div class="right">
                                <div class="title">{{ item.name }}</div>
                                <div class="time">时间：{{ item.liveTime }}</div>
                                <div class="bottom">
                                    <div>
                                        <span class="name">{{ item.teacherName }}</span>
                                        <span class="price">{{ item.isFree == 1 ? "¥"+item.price : "¥免费" }}</span>
                                    </div>
                                    <div v-if="item.type == 1" class="btn" @click="onYuYue(item)">进入</div>
                                    <div v-if="item.type == 2" class="btn" @click="onYuYue(item)">预约</div>
                                    <div v-if="item.type == 3" class="btn" @click="onYuYue(item)">购买</div>
                                    <div v-if="item.type == 4" class="btn" @click="onYuYue(item)">待开播</div>
                                    <div v-if="item.type == 5" class="btn" @click="onYuYue(item)">回放</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <van-empty v-else class="custom-image" :image="noDataIcon" description="暂无课程" />
                </van-pull-refresh>
            </van-tab>

            <van-tab title="全部" name="b">
                <van-pull-refresh class="aaa" v-model="allLoading" @refresh="allRefresh">
                    <div class="allBox" v-if="allList.length > 0">
                        
                        <!-- 全部直播卡片 -->
                        <div class="item" v-for="(item, index) in allList" :key="index" @click="onYuYue2(item)">
                            <div class="left">
                                <div class="avatar">
                                    <img :src="item.teacherHeaderPath" class="avatar_img" />
                                    <div class="avatar_icon"></div>
                                </div>
                                <div class="avatar_text">{{ item.typeName }}</div>
                            </div>
                            <div class="right">
                                <div class="title">{{ item.name }}</div>
                                <div class="time">时间：{{ item.liveTime }}</div>
                                <div class="bottom">
                                    <div>
                                        <span class="name">{{ item.teacherName }}</span>
                                        <span class="price">{{ item.isFree == 1 ? "¥"+item.price : "¥免费" }}</span>
                                    </div>
                                    <div v-if="item.type == 1" class="btn" @click="onYuYue(item)">进入</div>
                                    <div v-if="item.type == 2" class="btn" @click="onYuYue(item)">预约</div>
                                    <div v-if="item.type == 3" class="btn" @click="onYuYue(item)">购买</div>
                                    <div v-if="item.type == 4" class="btn" @click="onYuYue(item)">待开播</div>
                                    <div v-if="item.type == 5" class="btn" @click="onYuYue(item)">回放</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <van-empty v-else class="custom-image" :image="noDataIcon" description="暂无课程" />
                </van-pull-refresh>
            </van-tab>
            
        </van-tabs>

    </div>
</template>

<script>
import { liveList, liveYuYue } from "@/api/home.js";
import { Toast } from 'vant'
import {kbt} from '@/utils/utils.js'

export default {
    data() {
        return {
            activeName: "a",
            noDataIcon: require('../../assets/all_Icon/drawable-xxhdpi/no_data.png'),

            todayList: [], //今日
            todayLoading:false, //下拉
            todayLoading2:false, //上拉
            todayFinished:false,

            allList: [], //全部
            allLoading:false, //下拉
            allLoading2:false, //上拉
            allFinished:false,
            
        }
    },
    created() {
        this.getLiveList(1) //今日
        this.getLiveList(0) //全部
    },
    methods: {
        // 获取列表
        getLiveList(type=0) {
            let data = new FormData()
            // data.append('page',page)
            data.append('type',type)

            liveList(data).then((res) => {
                console.log('获取列表---',res)
                if(res.data.code == 0){
                    if(type == 0) {this.allList = res.data.data; this.allLoading = false}
                    if(type == 1) {this.todayList = res.data.data; this.todayLoading = false}
                }
            })
        },
        // 点击卡片整体进入直播
        onYuYue2(item){
            console.log('预约直播---------',item)
            window.sessionStorage.setItem('liveName',item.name)

            let coursePackageId = ''
            let courseId = ''
            if(item.coursePackageId){
                coursePackageId = '/'+ item.coursePackageId
            }else{
                coursePackageId = '/'+ null
            }
            if(item.courseId){
                courseId = '/'+ item.courseId
            }else{
                courseId = ''
            }
            let channelId = kbt.encrypt(item.channelId) //直播id加密

            let state = "1";
            
            this.$router.push('/zhibo/'+ item.id+ '/'+ channelId+ '/'+ coursePackageId+ '/'+ courseId+'/'+state+'/'+item.liveType)
        },
        // 点击预约按钮
        onYuYue(item){
            if(item.type==1){ //进入直播
                // this.$router.push({
                //     path: "/zhibo", 
                //     query: { 
                //         id: item.id, //直播id
                //         coursePackageId:item.coursePackageId,
                //         courseId: item.courseId,
                //         channelId: item.channelId,
                //         liveTime:item.liveTime
                //     } 
                // })
                
                let coursePackageId = ''
                let courseId = ''
                
                if(item.coursePackageId){
                    coursePackageId = '/'+ item.coursePackageId
                }else{
                    coursePackageId = '/'+ null
                }
                if(item.courseId){
                    courseId = '/'+ item.courseId
                }else{
                    courseId = ''
                }
                let channelId = kbt.encrypt(item.channelId) //直播id加密
                let state = "1";
                this.$router.push('/zhibo/'+ item.id+ '/'+ channelId+ '/'+ coursePackageId+ '/'+ courseId+'/'+state+'/'+item.liveType)
            }
            if(item.type==2){ //预约直播
                let data = new FormData()
                data.append('liveId',item.id)
                liveYuYue(data).then(res=>{
                    if(res.data.code == 0){
                        this.$toast('预约直播成功')
                        this.getLiveList(0,1)
                        this.getLiveList(0,0)
                    }
                })
            }
            if(item.type==3){ //购买
                this.$router.push({
                    path:'/orderdetail',
                    query:{
                        liveId:item.id,
                        price:item.price
                    }
                })
            }
            if(item.type==4){ //待开播
                // this.$router.push({
                //     path:'/zhibo',
                //     query:{
                //         liveId:item.id,
                //         channelId:item.channelId,
                //         coursePackageId:item.coursePackageId,
                //         courseId:item.courseId,
                //         liveTime:item.liveTime
                //     }
                // })
                
                let coursePackageId = ''
                let courseId = ''
                if(item.coursePackageId){
                    coursePackageId = '/'+ item.coursePackageId
                }else{
                    coursePackageId = '/'+ null
                }
                if(item.courseId){
                    courseId = '/'+ item.courseId
                }else{
                    courseId = ''
                }
                let channelId = kbt.encrypt(item.channelId) //直播id加密
                let state = "1";
                this.$router.push('/zhibo/'+ item.id+ '/'+ channelId+ '/'+ coursePackageId+ '/'+ courseId+'/'+state+'/'+item.liveType)
            }
            if(item.type==5){ //回放 
                // this.$router.push({
                //     path:'/zhibo',
                //     query:{
                //         liveId:item.id,
                //         channelId:item.channelId,
                //         coursePackageId:item.coursePackageId,
                //         courseId:item.courseId,
                //         liveTime:item.liveTime
                //     }
                // })
                
                let coursePackageId = ''
                let courseId = ''
                let state = '' // state=5回放
                if(item.coursePackageId){
                    coursePackageId = '/'+ item.coursePackageId
                }else{
                    coursePackageId = '/'+ null
                }
                if(item.courseId){
                    courseId = '/'+ item.courseId
                }else{
                    courseId = ''
                }
                if(item.type == 5){
                    state = '2'
                }else{state = '1'}
                let channelId = kbt.encrypt(item.channelId) //直播id加密
                this.$router.push('/zhibo/'+ item.id+ '/'+ channelId+ '/'+ coursePackageId+ '/'+ courseId+'/'+state+'/'+item.liveType)
            }
            this.getLiveList(0,1)
            this.getLiveList(0,0)
        },

        // 今日 下拉刷新
        TodayRefresh(){
            this.getLiveList(1)
        },
        // 全部 下拉刷新
        allRefresh(){
            this.getLiveList(0)
        }
    }
}
</script>

<style lang="less" scoped>
.homeLiveList {
    width: 100%;
    height: 100%;
    
    .todayBox, .allBox{
        min-height: 85vh;
        overflow: auto;
        
        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #dddddd;
            padding: 18px 0;
            &:last-child {
                border: none;
            }
            .left {
                width: 72px;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                margin-left: 10px;
                .avatar {
                    border-radius: 50%; /*no*/
                    border: 3px solid #fff;
                    background: #fff;
                    width: 72px;
                    height: 72px;
                    display: flex;
                    justify-content: center;
                    position: relative;
                    background: url("../../assets/all_Icon/drawable-xhdpi/icon_home_live.png")
                        no-repeat;
                    background-size: 100% 100%;
                    .avatar_img {
                        width: 75%;
                        position: absolute;
                        bottom: 6px;
                        object-fit: cover;
                    }
                }
                .avatar_text {
                    position: absolute;
                    bottom: 0;
                    z-index: 9;
                    background: #f8ab50;
                    padding: 1px 8px;
                    text-align: center;
                    width: 90%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    overflow: hidden;
                    white-space: nowrap;
                    color: #fff;
                    font-size: 12px;
                    border-radius: 500px; /*no*/
                }
            }
            .right {
                flex: 1;
                padding: 0 0 0 21px;
                .title {
                    font-size: 15px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 550;
                    color: #333333;
                    line-height: 22px;
                    margin-bottom: 6px;
                }
                .time {
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 18px;
                }
                .bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .name {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #555555;
                        line-height: 19px;
                    }
                    .price {
                        font-size: 13px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #e53d3d;
                        line-height: 18px;
                        margin-left: 10px;
                    }
                    .btn {
                        margin-right: 10px;
                        background: #5d7dff;
                        border-radius: 500px; /*no*/
                        width: 67px;
                        height: 28px;
                        line-height: 28px;
                        text-align: center;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                    }
                }
            }
        }
    }
    
}
</style>
